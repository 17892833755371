<template>
    <div class="d-full d-none">
        <div class="product-body-left">
            <div class="h-100vh d-flex py-5 mx-auto flex-column">
                <div class="container my-auto">
                    <div class="content">
                        <div class="content-header">
                            <a href="#" @click.prevent="$emit('close')" class="text-dark">
                                <span class="fas fa-times-circle"></span>
                            </a>
                            <h1 class="ml10">
                                <span class="text-wrapper">
                                    <span class="letters">Podcast Studio Experience</span>
                                </span>
                            </h1>
							<h3 class="intro-line sub-header">
                                100 <small>USD</small>
                            </h3>
                        </div>

                        <div class="content-body">
                            <p class="lead mb-3">
                                Podcasts and videos are two of the most popular ways to engage with your audience. Utilizing these platforms gives clients and prospects the opportunity to hear you discuss important retirement topics on a regular basis and positions you as a trusted financial services professional and leader in your area.
                            </p>

                            <p>
                                <b>The Podcast Studio Experience includes:</b>
                            </p>

                            <ul class="mb-4">
                                <li>10-minute video podcast with professional co-host</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="product-image-container">
            <div
                class="product-image"
                :style="{'background-image': 'url(' + getImageUrl('images/RadioRoom.jpg') + ')'}"
            ></div>
            <div class="navigation">
                <a
                    @click.prevent="$emit('switch', '#product-professional-portraits')"
                    href="#" class="fas fa-arrow-circle-left"
                ></a>
            </div>
        </div>

        <div class="product-image-overlay"></div>
    </div>
</template>

<script>
export default {
    methods: {
        getImageUrl(path) {
            return 'https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/' + path;
        },
    }
}
</script>