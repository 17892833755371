<template>
    <div class="d-full d-none">
        <div class="product-body-left">
            <div class="h-100vh d-flex py-5 mx-auto flex-column">
                <div class="container my-auto">
                    <div class="content">
                        <div class="content-header">
                            <a href="#" @click.prevent="$emit('close')" class="text-dark">
                                <span class="fas fa-times-circle"></span>
                            </a>
                            <h1 class="ml10">
                                <span class="text-wrapper">
                                    <span class="letters">Video Bundle</span>
                                </span>
                            </h1>
                            <h3 class="intro-line sub-header">
                                400 <small>USD</small>
                            </h3>
                        </div>

                        <div class="content-body">
                            <div class="mb-3">
                                <p class="lead">
									Now more than ever, video is critical to the success of your company. At our state-of-the-art green room studio in Minnesota, you'll record three custom videos that can be displayed on your website so you can introduce yourself to prospective clients before meeting them in person.
                                </p>
                            </div>

                            <p><b>The Video Bundle includes:</b></p>
                            <ul class="mb-4">
                                <li>Three 30-second commercials</li>
                            </ul>
							
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="product-image-container">
            <div
                class="product-image"
                :style="{'background-image': 'url(' + getImageUrl('images/GreenRoom.jpg') + ')'}"
            ></div>
            <div class="navigation">
                <a
                    @click.prevent="$emit('switch', '#product-video-campaign')"
                    href="#" class="fas fa-arrow-circle-right"
                ></a>
            </div>
        </div>

        <div class="product-image-overlay"></div>
    </div>
</template>

<script>
export default {
    methods: {
        getImageUrl(path) {
            return 'https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/' + path;
        },
    }
}
</script>