<template>
    <div class="py-3" :class="{ 'border-top': border }">
        <div class="d-flex justify-content-beween">
            <a
                @click.prevent="$emit('clicked')"
                href="#"
                class="mt-0.5 me-3 text-dark"
            >
                <i v-if="show" class="fas fa-minus"></i>
                <i v-else class="fas fa-plus"></i>
            </a>
            <div class="flex-grow-1">
                <p
                    @click.prevent="$emit('clicked')"
                    class="fw-bold cursor-pointer mb-0"
                >
                    <slot name="title"></slot>
                </p>
                <p v-if="show" class="text-muted lh-base mt-1 mb-0">
                    <slot name="body"></slot>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["show", "border"],
};
</script>