<template>
    <div>
        <Navbar/>
        <section class="hero bg-dark">
            <div class="position-relative container text-light px-3 px-sm-0">
                <div class="row">
                    <div class="col-12">
                        <div class="triangle-bottomright d-none d-md-block" style="position: absolute; top: -50px;"></div>
                        <h1 class="position-relative display-4 fw-bolder mb-5 ls-1">
                            Gradient Positioning <br>
                            <span style="opacity: 70%">Systems</span>
                        </h1>
                    </div>
                    <!-- <div class="col-md-8 col-xl-5 position-relative">
                        <p class="lead mb-0">Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat.</p>
                    </div> -->
                </div>
            </div>
        </section>
        <section class="py-8">
            <div class="container text-center">
                <div class="row mb-6">
                    <div class="col-lg-7 mx-auto">
                        <p class="text-uppercase fw-bold text-primary ls-1 mb-2">Distinction through innovation</p>
                        <h1 class="fw-bolder mb-4">Take your practice <span id="typeJsElement"></span></h1>
                        <p class="lead text-muted">We are inspired by innovation and rooted in results. At Gradient Positioning Systems we don't produce products, we produce solutions.</p>
                    </div>
                </div>
                <div class="row">
                    <FeatureTile class="col-xl-3 col-md-6 mb-5 mb-xl-4">
                        <template #svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                            </svg>
                        </template>
                        <template #title>
                            Distinguish your identity
                        </template>
                        <template #body>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit atque excepturi nam voluptatibus praesentium.
                        </template>
                    </FeatureTile>
                    <FeatureTile class="col-xl-3 col-md-6 mb-5 mb-xl-4">
                        <template #svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </template>
                        <template #title>
                            Enhance your visibility
                        </template>
                        <template #body>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit atque excepturi nam voluptatibus praesentium.
                        </template>
                    </FeatureTile>
                    <FeatureTile class="col-xl-3 col-md-6 mb-5 mb-xl-4">
                        <template #svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                            </svg>
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                            </svg> -->
                        </template>
                        <template #title>
                            Unify your marketing efforts
                        </template>
                        <template #body>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit atque excepturi nam voluptatibus praesentium.
                        </template>
                    </FeatureTile>
                    <FeatureTile class="col-xl-3 col-md-6 mb-5 mb-xl-4">
                        <template #svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                            </svg>
                        </template>
                        <template #title>
                            Increase your production
                        </template>
                        <template #body>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit atque excepturi nam voluptatibus praesentium.
                        </template>
                    </FeatureTile>
                </div>
            </div>
        </section>
        <section id="print-design" class="py-8 bg-light">
            <div class="container">
                <PrintDesign class="d-none d-xl-block" :layout="0"/>
                <PrintDesign class="d-block d-xl-none" :layout="1"/>
            </div>
        </section>
        <section id="web-design" class="py-8">
            <div class="container">
                <WebDesign class="d-none d-xl-block" :layout="0"/>
                <WebDesign class="d-block d-xl-none" :layout="1"/>
            </div>
        </section>
        <section id="audio-video" class="py-8 bg-light">
            <div class="container">
                <AudioVideo class="d-none d-xl-block" :layout="0"/>
                <AudioVideo class="d-block d-xl-none" :layout="1"/>
            </div>
        </section>
        <section id="financially-tuned" class="py-8">
            <div class="container">
                <Radio/>
            </div>
        </section>
        <section class="pb-8">
            <div class="container">
                <div class="position-relative p-3 p-md-5 rounded-3 shadow-sm bg-light text-center overflow-hidden">
                    <div class="position-relative">
                        <div class="row">
                            <div class="col-lg-8 mx-auto mb-4">
                                <h3 class="fw-bolder mb-3">Get In Touch</h3>
                                <!-- <p class="fs-lg text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio quisquam, placeat nihil minima illo natus quas magnam ipsam perferendis dolor dignissimos velit recusandae eius.</p> -->
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
                                <a href="https://www.google.com/maps/place/4105+Lexington+Ave+N,+Arden+Hills,+MN+55126/@45.0654487,-93.1488188,17z/data=!3m1!4b1!4m2!3m1!1s0x52b329aa6b3b494d:0xb02dd12f69ca72c7" target="_blank" class="fw-bolder text-muted text-decoration-none">
                                    4105 Lexington Avenue North <br>
                                    Arden Hills, MN 55126
                                </a>
                            </div>
                            <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
                                <a href="tel:8779010894" class="fw-bolder text-muted text-decoration-none">877-901-0894</a> <br>
                                <a href="mailto:info@gradientps.com" class="fw-bolder text-muted text-decoration-none">info@gradientps.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 bg-dark">
            <div class="container text-center">
                <p class="text-muted mb-0">&copy; 2021 Gradient Positioning Systems, LLC. All rights reserved.</p>
            </div>
        </section>
    </div>
</template>

<script>
import AudioVideo from '../components/home/AudioVideo.vue'
import FeatureTile from '../components/FeatureTile.vue'
import Navbar from '../components/home/Navbar.vue'
import PrintDesign from '../components/home/PrintDesign.vue'
import Radio from '../components/home/Radio.vue'
import Typed from 'typed.js'
import WebDesign from '../components/home/WebDesign.vue'

export default {
    name: "Home",
    components: {
        AudioVideo, FeatureTile, Navbar, PrintDesign, Radio, WebDesign
    },
    computed: {
        layoutVersion() {
            return this.$route.query.layout ? this.$route.query.layout : 1;
        }
    },
    mounted() {
        var options = {
            strings: ['further.', 'faster.'],
            typeSpeed: 80,
            backSpeed: 80,
            startDelay: 2000,
            backDelay: 1600,
            loop: true,
            smartBackspace: true,
        };

        var typed = new Typed('#typeJsElement', options);
    }
};
</script>

<style scoped lang="scss">
    .hero {
        background: url(https://gradient-websites.s3.amazonaws.com/gps/website/images/bg1.png) no-repeat center center fixed;
        background-size: cover;
        padding: 15em 0;
        position: relative;
        &:before {
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            background-color: rgba(0,0,0,0.5);
            background: radial-gradient(circle, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0.75) 90%);
        }
    }
    .triangle-bottomright {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 200px 200px;
        border-color: transparent transparent #8C001A transparent;
    }
</style>

<style>
.image-cover {
    height: 350px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.grayscale {
    filter: grayscale(1) !important;
}
</style>
