<template>
    <div class="row">
        <div class="col-lg-6 d-flex align-items-center">
            <div class="pe-5">
                <h3 class="fw-bolder mb-3">Financially Tuned</h3>
                <p class="fs-lg text-muted">
                    Financially Tuned Media Group was formed to provide
                    leading financial services professionals with the media
                    exposure and business solutions needed for definitive
                    success in an ever-evolving landscape.
                </p>
            </div>
        </div>
        <div class="col-lg-6 d-flex align-items-center mt-4 mt-lg-0">
            <img
                class="img-fluid shadow-lg rounded-5"
                src="https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/images/jonathan-velasquez-c1ZN57GfDB0-unsplash.jpg"
                alt=""
            />
        </div>
    </div>
</template>