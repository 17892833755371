<template>
    <div>
        <div class="row">
            <div
                class="col-lg-10 col-xl-6 d-flex align-items-center"
            >
                <img
                    class="d-none d-xl-block img-fluid img-skewed-item shadow-lg rounded-5"
                    :class="{ 'mt-5': layout == 1 }"
                    src="https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/images/hello-i-m-nik-kojrHziHvx4-unsplash.jpg"
                    alt=""
                />
                <div
                    class="d-block d-xl-none image-cover shadow-lg rounded-5"
                    style="
                        background-image: url('https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/images/hello-i-m-nik-kojrHziHvx4-unsplash.jpg');
                    "
                ></div>
            </div>
            <div class="col-lg-10 col-xl-6 d-flex align-items-center">
                <div class="ps-xl-5 pt-5 pt-xl-0">
                    <div class="mb-4">
                        <h3 class="fw-bolder mb-3">Print Design</h3>
                        <p class="fs-lg text-muted">
                            Your image is 90 percent of what you’re selling. We
                            design the public face of your company to be a look
                            and feel your clients will remember.
                        </p>
                    </div>
                    <ul v-if="layout == 1" class="list-unstyled mb-0">
                        <li>
                            <CollapseItem
                                v-for="(product, index) in products"
                                :key="index"
                                :show="selectedProduct === product"
                                :border="index !== 0"
                                @clicked="updateSelection(product)"
                            >
                                <template #title>
                                    {{ product.name }}
                                </template>
                                <template #body>
                                    {{ product.description }}
                                </template>
                            </CollapseItem>
                        </li>
                    </ul>
                    <ul v-else class="list-unstyled pt-3">
                        <li
                            v-for="(product, index) in products"
                            :key="index"
                            class="mb-3"
                        >
                            <p class="fw-bold mb-1">{{ product.name }}</p>
                            <p class="text-muted">{{ product.description }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CollapseItem from "../CollapseItem.vue";

export default {
    props: ["layout"],
    data: () => ({
        products: [
            {
                name: "Logo Design",
                description:
                    "As the keystone of your brand and image, your logo will be built on the personality of your practice through the use of print, Web and video multimedia.",
            },
            {
                name: "Identity Package",
                description:
                    "Maximize the impact of your brand by leveraging all the tools you use. Brand yourself as a leader in your marketplace. We do not use “out-of-the-box” kits. Our team of Web and graphic designers create enticing, client-friendly websites and business materials with you in mind.",
            },
            {
                name: "Brochure Design",
                description:
                    "A well-conceived summary of your practice will help your clients and prospects understand how you can help.",
            },
            {
                name: "Welcome Kit",
                description:
                    "Your first impression can be organized and cohesive and every bit of YOU. Create a personalized welcome kit to give your clients and prospects that introduces you as the expert, and provides detailed explanations of some of the services and products you offer.",
            },
            {
                // Trade Show Presentation
                name: "Trade Show",
                description:
                    "Ensure memorable seminars and events by utilizing high-impact imagery. When you set up a seminar, your prospects should experience the full professionalism of your practice – even in a restaurant or hotel meeting room. Our package of trade show collateral helps you look your best, even when you aren’t playing on your home field.",
            },
        ],
        selectedProduct: null,
    }),
    components: {
        CollapseItem,
    },
    methods: {
        updateSelection(product) {
            this.selectedProduct === product
                ? (this.selectedProduct = null)
                : (this.selectedProduct = product);
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
</style>