<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white shadow">
        <div class="container py-2">
            <a class="navbar-brand" href="#">
                <img src="https://gradient-websites.s3.amazonaws.com/gps/website/images/GPS-logo.png" alt="" style="height:22px; width:auto">
            </a>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item px-2">
                        <a class="nav-link" href="#print-design">Print Design</a>
                    </li>
                    <li class="nav-item px-2">
                        <a class="nav-link" href="#web-design">Web Design</a>
                    </li>
                    <li class="nav-item px-2">
                        <a class="nav-link" href="#audio-video">Audio &amp; Video</a>
                    </li>
                    <li class="nav-item pl-2">
                        <a class="nav-link" href="#financially-tuned">Financially Tuned</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>