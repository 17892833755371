<template>
    <div class="d-full d-none">
        <div class="product-body-left">
            <div class="h-100vh d-flex py-5 mx-auto flex-column">
                <div class="container my-auto">
                    <div class="content">
                        <div class="content-header">
                            <a href="#" @click.prevent="$emit('close')" class="text-dark">
                                <span class="fas fa-times-circle"></span>
                            </a>
                            <h1 class="ml10">
                                <span class="text-wrapper">
                                    <span class="letters">
                                        New Generation Seminar
                                    </span>
                                </span>
                            </h1>
                            <h1 class="ml10">
                                <span class="text-wrapper">
                                    <span class="letters">
                                        Video Campaign
                                    </span>
                                </span>
                            </h1>
                            <h3 class="intro-line sub-header">
                                480 <small>USD</small>
                            </h3>
                        </div>

                        <div class="content-body">
                            <p class="lead">
                                Create a series of <b>15 engaging videos</b> to
                                help fill your New Generation Retirement
                                seminars, set appointments afterward and turn
                                new prospects into long-term clients. We do the
                                scripting, production and editing, but the
                                videos are 100 percent you.
                            </p>
                            <p class="lead">
                                The final product is a series of emails
                                featuring your videos, which will be hosted on
                                splash pages that we also set up for you. Note:
                                There is a monthly fee for splash page hosting.
                            </p>
                            <p class="lead">
                                Put video to work in helping you host
                                better-attended, more productive seminars.
                            </p>
                            <p class="disclaimer mt-2">
                                * Seminar splash page required. Additional cost
                                may apply. <br />
                                * Studio time is limited. Please contact your VP
                                of marketing prior to your visit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="product-image-container">
            <div
                class="product-image"
                :style="{'background-image': 'url(' + getImageUrl('images/TVinterview.jpg') + ')'}"
            ></div>
            <div class="navigation">
                <a
                    @click.prevent="$emit('switch', '#product-video-bundle')"
                    href="#" class="fas fa-arrow-circle-left"
                ></a>
                <a
                    @click.prevent="$emit('switch', '#product-professional-portraits')"
                    href="#" class="fas fa-arrow-circle-right"
                ></a>
            </div>
        </div>

        <div class="product-image-overlay"></div>
    </div>
</template>

<script>
export default {
    methods: {
        getImageUrl(path) {
            return 'https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/' + path;
        },
    }
}
</script>