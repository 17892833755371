<template>
    <div class="card h-100">
        <div class="card-body">
            <img
                class="card-icon"
                :src="imageUrl"
            />
            <h4>
                <slot name="title"></slot>
            </h4>
            <p class="small mb-0">
                <slot name="body"></slot>
            </p>
        </div>
        <div class="card-footer">
            <a
                @click.prevent="$emit('clicked')"
                href="#"
                class="text-muted text-decoration-none"
                >Learn More &rarr;</a
            >
        </div>
    </div>
</template>

<script>
export default {
    props: ['image-url'],
}
</script>