<template>
    <div>
        <div class="row">
            <div class="col-lg-10 col-xl-7 order-2 order-xl-1">
                <div class="pe-xl-5 pt-5 pt-xl-0">
                    <div class="mb-4">
                        <h3 class="fw-bolder mb-3">Web Design</h3>
                        <p class="fs-lg text-muted">
                            Your website is a highly-visible feature that
                            represents your brand. Blending form and function
                            provides your users with a memorable experience that
                            builds your credibility, your brand, and ultimately,
                            your success. We offer a variety of design options
                            to help you establish a strong online presence.
                        </p>
                    </div>
                    <ul v-if="layout == 1" class="list-unstyled mb-0">
                        <li v-for="(product, index) in products" :key="index">
                            <CollapseItem
                                :show="selectedProduct === product"
                                :border="index !== 0"
                                @clicked="updateSelection(product)"
                            >
                                <template #title>
                                    {{ product.name }}
                                </template>
                                <template #body>
                                    {{ product.description }}
                                </template>
                            </CollapseItem>
                        </li>
                    </ul>
                    <ul v-else class="list-unstyled pt-3">
                        <li
                            v-for="(product, index) in products"
                            :key="index"
                            class="mb-3"
                        >
                            <p class="fw-bold mb-1">{{ product.name }}</p>
                            <p class="text-muted">{{ product.description }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-10 col-xl-5 order-1 order-xl-2 d-flex align-items-center">
                <!-- <div class="img-skewed img-skewed-left" style="width: 120%">
                    <img
                        class="mb-5 img-fluid img-skewed-item shadow-lg rounded-5"
                        src="https://landkit.goodthemes.co/assets/img/screenshots/desktop/dashkit.jpg"
                        alt=""
                    />
                </div> -->
                <img
                    class="d-none d-xl-block img-fluid img-skewed-item shadow-lg rounded-5"
                    :class="{ 'mt-5': layout == 1 }"
                    src="https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/images/ben-kolde-b15nB4ykyxc-unsplash.jpg"
                    alt=""
                />
                <div
                    class="d-block d-xl-none image-cover shadow-lg rounded-5"
                    style="background-image: url('https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/images/ben-kolde-b15nB4ykyxc-unsplash.jpg');"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import CollapseItem from "../CollapseItem.vue";

export default {
    props: ["layout"],
    data: () => ({
        products: [
            {
                name: "Premium Website",
                description:
                    "For many of your prospects, the first impression of you and your business happens online... are you ready? In today’s technology and information age, people rarely get to know you or your business through face-to-face interactions. We use a variety of Web design tools including HTML, JavaScript, CSS and WordPress to keep you with, and ahead of, today’s standards.",
            },
            {
                name: "Splash Page",
                description:
                    "Online lead generation is possible... and we can make it happen for you.",
            },
            {
                name: "Classic Landing Page",
                description:
                    "Landing pages increase your online visibility and can increase your business by either driving potential clients back to your website or by capturing leads right on the landing page itself.",
            },
        ],
        selectedProduct: null,
    }),
    components: {
        CollapseItem,
    },
    methods: {
        updateSelection(product) {
            this.selectedProduct === product
                ? (this.selectedProduct = null)
                : (this.selectedProduct = product);
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.img-skewed-left .img-skewed-item {
    transform: rotateY(-35deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-skewed-left {
    perspective-origin: left center;
}

.img-skewed {
    perspective: 1500px;
    transform-style: preserve-3d;
}
</style>