<template>
    <div class="so-content">
        <div
            class="main d-flex h-100vh py-5 mx-auto flex-column"
            :style="{'background-image': 'url(' + getImageUrl('images/GreenRoom.jpg') + ')'}"
        >
            <div class="my-auto">
                <div class="container pb-4 text-center cover">
                    <h3><b>Gradient Positioning Systems</b></h3>
                    <h1 class="cover-heading mb-4">Our Special Offers</h1>
                    <p class="lead mb-0">
                        To help you make the most of your time at Gradient.
                    </p>
                </div>

                <div class="container my-4">
                    <div class="row">
                        <div class="col-lg-10 mx-auto">
                            <div class="row text-center">
                                <div v-for="(offer, index) in offers" :key="index" class="col-xl col-sm-6 mb-4">
                                    <OfferTile class="card-animate" @clicked="selectOffer(offer)" :image-url="getImageUrl(offer.iconPath)">
                                        <template #title>
                                            {{ offer.name }}
                                        </template>
                                        <template #body>
                                            {{ offer.description }}
                                        </template>
                                    </OfferTile>
                                </div>
                            </div>

                            <p class="text-center disclaimer mt-2">
                                Studio time is limited. Please contact your VP of
                                marketing prior to your visit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <footer class="footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            Copyright &copy; 2019 Nextek, LLC. All rights
                            Reserved.
                        </div>
                    </div>
                </div>
            </footer>
        </div>

        <div class="overlay"></div>

        <VideoBundle id="product-video-bundle" @switch="switchPanel" @close="closePanel"/>
        <VideoCampaign id="product-video-campaign" @switch="switchPanel" @close="closePanel"/>
        <ProfessionalPortraits id="product-professional-portraits" @switch="switchPanel" @close="closePanel"/>
        <RadioPackage id="product-radio-package" @switch="switchPanel" @close="closePanel"/>
        
    </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import OfferTile from '../components/specialOffer/OfferTile.vue';
import ProfessionalPortraits from '../components/specialOffer/ProfessionalPortraits.vue';
import RadioPackage from '../components/specialOffer/RadioPackage.vue';
import VideoBundle from '../components/specialOffer/VideoBundle.vue';
import VideoCampaign from '../components/specialOffer/VideoCampaign.vue';

export default {
    name: "Special Offers",
    data: () => ({
        selectedOffer: null,
        offers: [
            {
                name: 'Video Bundle',
                description: 'Actively engage prospective clients.',
                iconPath: 'images/Video_Bundle-01.svg',
                imagePath: 'images/GreenRoom.jpg',
                elementId: '#product-video-bundle',
                letterAnimationOffset: 500,
            },
            {
                name: 'Video Campaign',
                description: 'Turn new prospects into long-term clients',
                iconPath: 'images/Video_Campaign-01.svg',
                imagePath: 'images/TVinterview.jpg',
                elementId: '#product-video-campaign',
                letterAnimationOffset: 500,
            },
            {
                name: 'Professional Portraits',
                description: 'Take professional quality portraits.',
                iconPath: 'images/Portrait-01.svg',
                imagePath: 'images/Portraits.jpg',
                elementId: '#product-professional-portraits',
                letterAnimationOffset: 500,
            },
            {
                name: 'Podcast Studio Experience',
                description: 'Position yourself as a trusted source.',
                iconPath: 'images/Radio-01.svg',
                imagePath: 'images/RadioRoom.jpg',
                elementId: '#product-radio-package',
                letterAnimationOffset: 500,
            },
        ]
    }),
    components: {
        OfferTile, ProfessionalPortraits, RadioPackage, VideoBundle, VideoCampaign
    },
    computed: {
        elementId() {
            return this.selectedOffer.elementId;
        }
    },
    methods: {
        getImageUrl(path) {
            return 'https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/' + path;
        },
        selectOffer(offer) {
            this.selectedOffer = offer;
            this.openPanel();
        },
        openPanel() {
			const target = $(this.elementId);

			anime.timeline()
				.add({
					targets: '.overlay',
					left: ['100%', '0'],
					easing: "linear",
					duration: 500,
					complete: (anim) => {
						target.removeClass('d-none');
						target.find('.content').addClass('d-none');
						this.animateIn(target, this.selectedOffer.letterAnimationOffset);
					}
				}).add({
					targets: '.overlay',
					left: ['0', '-100%'],
					easing: "easeOutQuad",
					duration: 100,
				});
        },
        closePanel() {
            const target = $(this.elementId);
			
			anime.timeline()
				.add({
					targets: '.overlay',
					left: ['100%', '0'],
					easing: "easeInQuad",
					duration: 500,
					complete: function(anim) {
						target.addClass('d-none');
					}
				}).add({
					targets: '.overlay',
					left: ['0', '100%'],
					easing: "easeOutQuad",
					duration: 800,
					delay: 300
				});

            this.selectedOffer = null;
            
			this.animateCoverIn(1200);
        },
        switchPanel(newElementId) {
            const current = $(this.elementId);
			const target = $(newElementId);

			anime.timeline()
				.add({
					targets: '.overlay',
					left: ['100%', '0'],
					easing: "linear",
					duration: 500,
					complete: (anim) => {
						current.addClass('d-none');
						target.removeClass('d-none');
						target.find('.content').addClass('d-none');
						this.animateIn(target, this.selectedOffer.letterAnimationOffset);
					}
				}).add({
					targets: '.overlay',
					left: ['0', '-100%'],
					easing: "easeOutQuad",
					duration: 100,
                    complete: (anim) => {
                        this.selectedOffer = this.offers.find(offer => offer.elementId === newElementId);
                    }
				});
        },
        animateCoverIn(delay) {
			
			anime.timeline()
				.add({
					targets: '.cover',
					top: ['-20px', '0'],
					opacity: ['0', '1'],
					easing: "easeOutCubic",
					duration: 2000,
					delay: delay,
				}).add({
					targets: '.main .card-animate',
					top: ['45px', '0'],
					opacity: ['0', '1'],
					easing: "easeOutCubic",
					duration: 1800,
					delay: anime.stagger(100),
				}, 0).add({
					targets: '.disclaimer',
					opacity: ['0', '0.75'],
					easing: "easeOutCubic",
					duration: 2000,
					delay: 500 + delay,
				}, 0);
        },
        animateIn(target, letterAnimationOffset) {
			
			// Wrap every letter in a span
			$('.ml10 .letters').each(function(){
				$(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
			});

			anime.timeline()
					.add({
						targets: '.product-image-overlay',
						right: ['-40%', '0'],
						easing: "easeInQuad",
						duration: 500,
						begin: function() {
							target.find('.content').removeClass('d-none');
						}
					}).add({
						targets: '.product-image-container',
						opacity: ['0', '1'],
						easing: "linear",
						duration: 100,
					}).add({
						targets: '.product-image-overlay',
						right: ['0', '40%'],
						easing: "easeOutQuad",
						duration: 1800,
						delay: 300,
					});

			anime.timeline()
				.add({
					targets: '.ml10 .letter',
					rotateY: [-90, 0],
					duration: 500,
					delay: anime.stagger(40, {start: letterAnimationOffset})
				}).add({
					targets: '.sub-header, .product-close',
					opacity: [0, 1],
					duration: 2500,
					delay: 1000,
				}, 0).add({
					targets: '.content-body',
					opacity: [0, 1],
					top: ['20px', '0'],
					easing: "easeOutQuad",
					duration: 1000,
					delay: 1700,
				}, 0);
		}
    },
    mounted() {
        console.log("Icon by Freepik check them out at https://www.flaticon.com/authors/freepik or https://www.freepik.com/");
        
        this.selectedOffer = [1];

        setTimeout(() => {
            anime.timeline().add({
                targets: ".overlay",
                left: ["0", "-100%"],
                easing: "easeOutCubic",
                duration: 1200,
            });
            this.animateCoverIn(800);
        }, 400);
    }
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Lora');

$text-darker: #AB9967;
$text-dark: #071831;
$text-light: #615F61;

.so-content {
    background: #ffffff;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    color: $text-light;
    background: lighten(#eef0f9, 0.2%);
    // background-image: linear-gradient(-75deg,#fff, #eef0f9 100%);
    background-image: linear-gradient(-75deg, #fff, #e4e7f4 100%);


    .lead {
        font-weight: 400;
    }

    h1 {
        font-size: 3rem;
        line-height: 1.125;
        color: $text-dark;
        letter-spacing: 0.02em;
        font-family: 'Lora', serif;
    }

    h3 {
        font-size: 1.05rem;
        color: #AB9967;
        letter-spacing: 0.06em;
        text-transform: uppercase;
    }

    .intro-line:before {
        content: '';
        position: relative;
        top: -5px;
        height: 1px;
        background-color: $text-darker;
        opacity: .8;
        display: inline-block;
        width: 20px;
        margin-right: 8px;
    }

    p {
        line-height: 1.7;
    }

    b {
        font-weight: 700;
    }

    .text-primary {
        color: #AB9967 !important;
    }

    ul {
        list-style: none;
        padding-inline-start: 50px;

        li {
            position: relative;
            margin-bottom: 0.75rem;

            &:before {    
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f058";
                color: $text-dark;
                font-size: 1.2em;
                position: absolute;
                left: -35px;
                top: -3px;
            }
        }
    }

    .mb-5 {
        margin-bottom: 2.5rem !important;
    }

    .bordered-top {
        border-top: 8px solid #22738A;
    }

    .h-100vh {
        min-height: 100vh;
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: #ffffff;
        z-index: 4;
        top: 0;
        // left: 100%;
    }


    .cover {
        position: relative;
    }


    .card-icon {
        max-height: 60px;
        margin-bottom: 0.75rem;
    }

    .card {
        transition: box-shadow 0.2s;
        
        &:hover {
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        }
        
        h4 {
            font-size: 1.15rem;
            font-family: 'Lora', serif;
        }

        p {
            line-height: 1.4;
        }
    }



    .card-footer {
        background: #fff;
        border-top: 0;
        font-size: 0.9rem;
        padding: 1.25rem;
        padding-top: 0;
    }



    .d-full {
        background: #fff;
        height: 100%;
        left: 0;
        overflow: scroll;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .product-close {
        font-size: 35px;
        margin-bottom: 1rem;
        color: #000;
        cursor: pointer;
    }

    .product-body-left {
        width: 60%;
        z-index: 4;
        position: relative;
        background: #fff;
        border-left: 10px solid darken(#F6F7FC, 2%);

        .content {
            position: relative;
            width: 100%;
            max-width: 50em;
            padding-left: 6vw;
            padding-right: 3vw;
        }
    }

    .content-header {
        margin-bottom: 3rem;
    }

    .content-body {
        position: relative;
        opacity: 0;
    }

    .product-image-container {
        position: fixed;
        top: 0;
        right: 0;
        overflow: hidden;
        width: 40%;
        height: 100vh;
        opacity: 0;
        border-right: 10px solid #fff;

        &:before {
            content: "";
            display: inline-block;
            top: 0;
            right: 0;
            width: 10px;
            position: fixed;
            height: 40px;
            background: $text-dark;
        }
        
        &:after {
            content: "";
            top: 0;
            left: 0;
            width: calc(100% - 10px);
            position: fixed;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 70%, rgba(0,0,0,0.7));
        }

        .product-image {
            top: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(https://uploads-ssl.webflow.com/5945c0691ffdf51725f42d2f/594a3b1ff904913c11e41fce_bureau1.jpg);
            background-position: 26% 0;
            opacity: 1;
        }

        .navigation {
            position: absolute;
            z-index: 5;
            bottom: 10px;
            right: 20px;
            font-size: 35px;
            
            a {
                text-decoration: none;
                color: #fff;
                margin-right: 10px;
            }
        }
    }


    .product-image-overlay {
        position: fixed;
        right: -100%;
        top: 0;
        width: 40%;
        height: 100vh;
        background: #F6F7FC;
        z-index: 2;
    }

    .main {
        background-position: center;
        background-size: cover;
        position: relative;
        &:before {
            background: #eff1f9;
            background-image: linear-gradient(-75deg, #fff, #e4e7f4 100%);
            content: "";
            height: 100%;
            left: 0;
            opacity: 0.92;
            position: absolute;top: 0;
            width: 100%;
        }
    }

    .disclaimer {
        font-size: 0.85em;
        position: relative;
        opacity: 0;
        z-index: 1;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        color: rgba($text-light, 0.5);
        padding: 5px 10px;
        font-size: 0.85rem;
        border-top: 1px solid rgba(#dee2e6, 0.3);
        // background: lighten(#eef0f9, 2%);
    }


    .ml10 {
        position: relative;
    }

    .ml10 + .ml10 {
        margin-top: -30px;
    }

    .ml10 .text-wrapper {
        position: relative;
        display: inline-block;
        padding-top: 0.2em;
        padding-right: 0.05em;
        padding-bottom: 0.1em;
        overflow: hidden;
    }

    .ml10 .letter {
        display: inline-block;
        transform-origin: 0 0;
    }


    @media (max-width: 1199.98px) {
        .product-image-container {
            display: none;
        }
        .product-body-left {
            width: 100%;
        }
    }

    @media (max-width: 991.98px) {
        body {
            font-size: 16px;
        }

        .lead {
            font-size: 1.15rem;
        }

        h1.ml10 {
            font-size: 2.4rem;
        }
    }

    @media (max-width: 767.98px) {
        h1.ml10 {
            font-size: 1.7rem;
        }

        h1.ml10 + h1.ml10 {
            margin-top: -20px;
        }
    }
}
</style>