<template>
    <router-view />
</template>

<style lang="scss">
    body {
        line-height: 1.7;
    }

    small, .small {
        line-height: 1.5;
    }

    h5 {
        font-size: 1.1em;
        margin-bottom: 1rem;
    }
    
    .ls-1 {
        letter-spacing: 0.02em !important;
    }

    .fs-lg {
        font-size: 1.15rem !important;
    }

    .fw-bolder {
        letter-spacing: 0.015em;
        font-weight: 900 !important;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    $spacing-6: 4.5rem;
    $spacing-8: 7rem;

    .mb-6 {
        margin-bottom: $spacing-6;
    }

    .my-8 {
        margin-bottom: $spacing-8;
        margin-top: $spacing-8;
    }

    .py-8 {
        padding-bottom: $spacing-8;
        padding-top: $spacing-8;
    }

    .pb-8 {
        padding-bottom: $spacing-8;
    }

    .fw-semibold {
        font-weight: 600;
    }

    .rounded-5 {
        border-radius: 0.8rem;
    }

    .bg-primary {
        background: #8C001A !important;
    }

    .bg-gradient-theme {
        background-image: linear-gradient(45deg ,#2f3250,#d92526 100%);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .text-primary {
        color: #8C001A !important;
    }
    
    .text-muted {
        // color: #869ab8 !important;
        // color: #bd9ea3 !important;
    }

    .nav-link {
        font-weight: 800;
    }

    .typed-cursor {
        position: relative;
        top: -0.1em;
        left: 0.04em;
    }
</style>
