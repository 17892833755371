<template>
    <div class="d-full d-none">
        <div class="product-body-left">
            <div class="h-100vh d-flex py-5 mx-auto flex-column">
                <div class="container my-auto">
                    <div class="content">
                        <div class="content-header">
                            <a href="#" @click.prevent="$emit('close')" class="text-dark">
                                <span class="fas fa-times-circle"></span>
                            </a>
                            <h1 class="ml10">
                                <span class="text-wrapper">
                                    <span class="letters"
                                        >Professional Portraits</span
                                    >
                                </span>
                            </h1>
                            <h3 class="intro-line sub-header">
                                100 <small>USD</small>
                            </h3>
                        </div>

                        <div class="content-body">
                            <p class="lead">
                                Take professional portraits that you can use on
                                your marketing materials, social media channels,
                                business cards and more. Our professional
                                photographers work with you to capture photos
                                that you’re happy with and that meet your needs.
                            </p>
                            <p class="disclaimer mt-2">
                                *Studio time is limited. Please contact your VP
                                of marketing prior to your visit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="product-image-container">
            <div
                class="product-image"
                :style="{'background-image': 'url(' + getImageUrl('images/Portraits.jpg') + ')'}"
            ></div>
            <div class="navigation">
                <a
                    @click.prevent="$emit('switch', '#product-video-campaign')"
                    href="#" class="fas fa-arrow-circle-left"
                ></a>
                <a
                    @click.prevent="$emit('switch', '#product-radio-package')"
                    href="#" class="fas fa-arrow-circle-right"
                ></a>
            </div>
        </div>

        <div class="product-image-overlay"></div>
    </div>
</template>

<script>
export default {
    methods: {
        getImageUrl(path) {
            return 'https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/' + path;
        },
    }
}
</script>