<template>
    <div>
        <div class="row">
            <div
                class="col-lg-10 col-xl-6 d-flex align-items-center"
            >
                <img
                    class="d-none d-xl-block img-fluid img-skewed-item shadow-lg rounded-5"
                    :class="{ 'mt-5': layout == 1 }"
                    src="https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/images/jakob-owens-u1oQ3LvjOlA-unsplash.jpg"
                    alt=""
                />
                <div
                    class="d-block d-xl-none image-cover shadow-lg rounded-5"
                    style="
                        background-image: url('https://gradient-websites.s3-us-west-2.amazonaws.com/gradientps/images/jakob-owens-u1oQ3LvjOlA-unsplash.jpg');
                    "
                ></div>
            </div>
            <div class="col-lg-10 col-xl-6 d-flex align-items-center">
                <div class="ps-xl-5 pt-5 pt-xl-0">
                    <div class="mb-4">
                        <h3 class="fw-bolder mb-3">Audio &amp; Video</h3>
                        <p class="fs-lg text-muted">
                            Your image is 90 percent of what you’re selling. We
                            design the public face of your company to be a look and
                            feel your clients will remember.
                        </p>
                    </div>
                    <ul v-if="layout == 1" class="list-unstyled mb-0">
                        <li v-for="(product, index) in products" :key="index">
                            <CollapseItem
                                :show="selectedProduct === product"
                                :border="index !== 0"
                                @clicked="updateSelection(product)"
                            >
                                <template #title>
                                    {{ product.name }}
                                </template>
                                <template #body>
                                    {{ product.description }}
                                </template>
                            </CollapseItem>
                        </li>
                    </ul>
                    <ul v-else class="list-unstyled pt-3">
                        <li v-for="(product, index) in products" :key="index" class="mb-3">
                            <p class="fw-bold mb-1">{{ product.name }}</p>
                            <p class="text-muted">{{ product.description }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CollapseItem from "../CollapseItem.vue";

export default {
    props: ['layout'],
    data: () => ({
        products: [
            {
                name: "Reel Advisor",
                description:
                    "Through video, you have an opportunity to position your story, and brand, into information which provides viewers with more than a memory of the product or service. It subliminally places you as the expert and weighs heavy on the influence to purchase from an expert. We offer TWO ways to become a REEL advisor. Choose your experience: Reel Studio Experience or the Reel On-Location Experience.",
            },
            {
                name: "Consumer Videos",
                description:
                    "Your clients may purchase a product, but they BUY concepts – and there is no better way to position a concept than with a professionally-designed video customized to your practice. Choose from over 20 prepared videos, or schedule time in our green room for a customized video shoot.",
            },
            {
                name: "TV Advertisements",
                description:
                    "Today more than ever, your prospects are turning to TV to become education and get advice. Realizing the need to market in this area, we have designed campaigns to help you break into and dominate your local market.",
            },
            {
                name: "Website Video Enhancement",
                description:
                    "Video can be the ultimate welcome to your website and a great chance to tell the mission and story of your practice in your own words.",
            },
        ],
        selectedProduct: null,
    }),
    components: {
        CollapseItem,
    },
    methods: {
        updateSelection(product) {
            this.selectedProduct === product
                ? (this.selectedProduct = null)
                : (this.selectedProduct = product);
        },
    },
    mounted() { },
};
</script>