<template>
    <div>
        <div class="h-100 py-4 px-3 bg-light shadow-sm rounded-3">
            <div class="d-block" style="margin-top: -45px;">
                <div class="feature-block-icon bg-primary mb-4 text-white">
                    <slot name="svg"></slot>
                </div>
                <h5 class="fw-bold ls-1 mb-2">
                    <slot name="title"></slot>
                </h5>
                <!-- <p class="text-muted lh-base mb-2">
                    <slot name="body"></slot>
                </p> -->
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .feature-block-icon {
        border-radius: .25rem;
        display: inline-block;
        padding: .7rem;
        svg, img {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
</style>